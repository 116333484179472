import React, { Fragment, useState, useContext, useLayoutEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { navigate } from "@reach/router"
import styled from "styled-components"

// Import Global Context
import NavigationIntersectionObserverContext from "~context/navigationIntersectionObserver"

// Import Global Components
import PillButton from "~components/pillButton"

// Import Local Components
import Description from "./components/description"
import Credits from "./components/credits"
import Tags from "./components/tags"

const Navigation = styled.nav`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  pointer-events: ${({ isVisible }) => (isVisible ? "auto" : "none")};
  z-index: 1000;
  position: fixed;
  max-width: 800px;
  bottom: 1.45rem;
  left: 1.45rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-basis: 100%;
  transition: opacity 0.5s;
  @media (max-width: 800px) {
    left: 2.5vw;
    max-width: 95vw;
    bottom: 0.725rem;
  }
`

const BackButton = styled(PillButton)``

export default () => {
  const {
    allSanityTag: { edges: tags },
  } = useStaticQuery(graphql`
    query TagsQuery {
      allSanityTag {
        edges {
          node {
            name
            _id
          }
        }
      }
    }
  `)

  const [isVisible, setIsVisible] = useState(false)
  const [isProject, setIsProject] = useState(false)
  const [navigationState] = useContext(NavigationIntersectionObserverContext)

  useLayoutEffect(() => {
    setIsVisible(navigationState.isVisible)
    setIsProject(navigationState.isProject)
  }, [navigationState])

  const handleClick = () => {
    window.scroll({
      top: window.innerHeight,
      left: 0,
      behavior: "smooth",
    })
    navigate("/")
  }

  if (isProject) {
    return (
      <Navigation isVisible>
        <BackButton
          value={"Back"}
          onClick={handleClick}
          highlight={navigationState.data.highlight}
        />
      </Navigation>
    )
  } else {
    return (
      <Navigation isVisible={isVisible}>
        {navigationState.data ? (
          <Fragment>
            <Description description={navigationState.data.description} />
            <Credits
              credits={navigationState.data.credits}
              highlight={navigationState.data.highlight}
            />
            <Tags
              allTags={tags}
              projectTags={navigationState.data.tags}
              highlight={navigationState.data.highlight}
            />
          </Fragment>
        ) : null}
      </Navigation>
    )
  }
}
