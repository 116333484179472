import React from "react"
import styled from "styled-components"

import Credit from "./credit"

const Credits = styled.div``

export default ({ credits, highlight }) => (
  <Credits>
    {credits
      ? credits.map(credit => {
          return (
            <Credit
              key={`${credit.type}`}
              credit={credit}
              highlight={highlight}
            />
          )
        })
      : null}
  </Credits>
)
