import React, { useEffect, useContext } from "react"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"

// Import Global Contexts
import NavigationIntersectionObserverContext from "~context/navigationIntersectionObserver"

// Import Global Components
import Contact from "~components/contact"

// Create Local Components
const Header = styled.header`
  height: 100vh;
  width: 100%;
  z-index: 500;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  transition: opacity 1s;
  @media (max-width: 800px) {
    text-align: center;
  }
`

const DownArrow = styled.div`
  height: 0;
  width: 0;
  align-self: center;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #000;
  margin-bottom: 5vh;
  cursor: pointer;
`

const Text = styled.h1`
  width: 80vw;
  max-width: 800px;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  justify-content: center;
  font-size: 3rem;
  font-weight: lighter;
  @media (max-width: 800px) {
    width: 95vw;
    font-size: 2rem;
  }
`

Text.Link = styled.a`
  border-radius: 0.75rem;
  transition: color 0.3s;
  &:hover {
    color: #cccccc;
  }
`

export default () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
  })
  const [navigationState, setNavigationState] = useContext(
    NavigationIntersectionObserverContext
  )

  const handleClick = () => {
    window.scroll({
      top: window.innerHeight,
      left: 0,
      behavior: "smooth",
    })
  }

  useEffect(() => {
    if (inView) {
      if (navigationState.data) {
        navigationState.data.highlight = "#ffffff"
      }
      setNavigationState({
        isVisible: false,
        data: navigationState.data,
      })
    }
  }, [inView])

  return (
    <Header>
      <Text ref={ref}>
        <span>
          Jonathon is an art director & creative strategist based in New York.
        </span>
        <br />
        <br />
        <span>
          Partner at{" "}
          <Text.Link
            href="https://offline-projects.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Offline Projects
          </Text.Link>
          , a creative studio.
        </span>
      </Text>
      <DownArrow onClick={handleClick} />
      <Contact visible={inView} />
    </Header>
  )
}
