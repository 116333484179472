import React, { useContext, useEffect } from "react"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"

import NavigationIntersectionObserverContext from "~context/navigationIntersectionObserver"

// Import Global Components
import Contact from "~components/contact"
import Filters from "~components/filters"
import ProjectList from "~components/projectList"

const Footer = styled.footer`
  position: relative;
  z-index: 10;
  flex-shrink: 0;
  padding-top: 20vh;
`

const FooterContent = styled.div`
  display: flex;
  padding: 1.45rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  bottom: 0;
  left: 0;
  min-height: 100vh;
  right: 0;
`

const UpArrow = styled.div`
  height: 0;
  width: 0;
  margin-bottom: 20px;
  align-self: center;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #000;
  cursor: pointer;
`

const StyledProjectList = styled(ProjectList)``

export default () => {
  const [ref, inView] = useInView({
    rootMargin: "90% 0px 0px 0px",
    threshold: 0.7,
  })
  const [navigationState, setNavigationState] = useContext(
    NavigationIntersectionObserverContext
  )

  const handleClick = () => {
    window.scroll({
      top: window.innerHeight,
      left: 0,
      behavior: "smooth",
    })
  }

  useEffect(() => {
    if (inView) {
      if (navigationState.data) {
        navigationState.data.highlight = "#ffffff"
      }
      setNavigationState({
        isVisible: false,
        data: navigationState.data,
      })
    }
  }, [inView])

  return (
    <Footer ref={ref}>
      <FooterContent>
        <UpArrow onClick={handleClick} />
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            flexBasis: "100%",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Filters />
          <StyledProjectList />
        </div>
      </FooterContent>
      <Contact visible={inView} />
    </Footer>
  )
}
