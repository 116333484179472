import React from "react"
import styled from "styled-components"

import PillHref from "~components/pillHref"

const Contact = styled.div`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: ${({ visible }) => (visible ? "auto" : "none")};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1000;
  position: fixed;
  bottom: 1.45rem;
  right: 1.45rem;
  transition: opacity 0.5s;
  @media (max-width: 800px) {
    right: 2.5vw;
    bottom: 0.725rem;
  }
`

Contact.Button = styled(PillHref)`
  &:hover {
    background-color: var(--color-off-white);
    color: var(--color-primary);
  }
`

export default ({ visible }) => (
  <Contact visible={visible}>
    <Contact.Button value={"email"} link={"email"} />
    <Contact.Button value={"twitter"} link={"email"} />
    <Contact.Button value={"instagram"} link={"email"} />
  </Contact>
)
