import React from "react"
import { NavigationIntersectionObserverContextProvider } from "~context/navigationIntersectionObserver"

export default ({ children }) => {
  return (
    <NavigationIntersectionObserverContextProvider>
      {children}
    </NavigationIntersectionObserverContextProvider>
  )
}
