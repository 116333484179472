import React, { useState } from "react"

/*
  Navigation Intersection Observer Context

  This context is used to update our high-level navigation component based
  on elements appearing in the viewport.

  For instance, on the homepage whenever a new 'project' comes in the
  viewport, we can update the navigation to display information about
  the project.

  Or, if we enter the 'footer' or 'header' it can update to show
  more global information, or maybe even disappear.
*/
const NavigationIntersectionObserverContext = React.createContext()

const NavigationIntersectionObserverContextProvider = ({ children }) => {
  const [navigationState, setNavigationState] = useState({
    isVisible: false,
    data: null,
  })
  return (
    <NavigationIntersectionObserverContext.Provider
      value={[navigationState, setNavigationState]}
    >
      {children}
    </NavigationIntersectionObserverContext.Provider>
  )
}

const NavigationIntersectionObserverContextConsumer =
  NavigationIntersectionObserverContext.Consumer

export default NavigationIntersectionObserverContext
export {
  NavigationIntersectionObserverContextProvider,
  NavigationIntersectionObserverContextConsumer,
}
