import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import Project from "./projectListItem.js"

const ProjectList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const Projects = styled.div`
  min-height: 30vh;
  width: 80vw;
  margin-top: 1rem;
`

export default () => {
  const {
    allSanityProject: { nodes: projects },
  } = useStaticQuery(graphql`
    query ProjectListQuery {
      allSanityProject(sort: { order: DESC, fields: date }) {
        nodes {
          _id
          name
          client
          tags {
            name
          }
        }
      }
    }
  `)

  return (
    <ProjectList>
      <Projects>
        {projects.map(project => (
          <Project key={project._id} project={project} />
        ))}
      </Projects>
    </ProjectList>
  )
}
