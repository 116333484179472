export const onClientEntry = async () => {
  // IntersectionObserver polyfill for gatsby-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    require(`intersection-observer`)
    console.log(`👍 IntersectionObserver is polyfilled`)
  }
}

export const shouldUpdateScroll = ({
  prevRouterProps: { location: prevLocation },
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location)
  // current position will have a value if using history to return to a page
  if (currentPosition) {
    window.scrollTo(currentPosition)
  }
  return false
}
