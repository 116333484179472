import React from "react"
import styled from "styled-components"

import Pill from "~components/pill"

const PillButton = styled(Pill)`
  border: none;
  background-color: var(--color-primary);
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${({ highlight }) => highlight};
  }
`

export default props => {
  const { title, value, handleClick } = props
  return (
    <PillButton
      title={title}
      value={value}
      onClick={handleClick}
      showAs={"button"}
      {...props}
    />
  )
}
