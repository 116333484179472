import React, { useState, useContext, useEffect } from "react"
import styled from "styled-components"
import Color from "color"

import NavigationIntersectionObserverContext from "~context/navigationIntersectionObserver"

const Background = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -1;
  background-color: ${props => {
    return Color(props.highlight)
      .desaturate(0.9)
      .hex()
  }};
  transition: background-color 0.5s;
`

export default () => {
  const [highlight, setHighlight] = useState("#ffffff")
  const [navigationState] = useContext(NavigationIntersectionObserverContext)

  useEffect(() => {
    if (navigationState.data) {
      setHighlight(navigationState.data.highlight)
    }
  }, [navigationState])
  return <Background highlight={highlight} />
}
