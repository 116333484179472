import React, { useState } from "react"

/*
    Filter Context

    The filter context keeps track of any filters being applied via selected tags 
*/
const FilterContext = React.createContext()

const FilterContextProvider = ({ children }) => {
  const [filters, setFilters] = useState(["Featured"])

  return (
    <FilterContext.Provider value={[filters, setFilters]}>
      {children}
    </FilterContext.Provider>
  )
}

const FilterContextConsumer = FilterContext.Consumer

export default FilterContext
export { FilterContextProvider, FilterContextConsumer }
