import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import Filter from "~components/filter"

const Filters = styled.div``

export default () => {
  const {
    allSanityTag: { edges: tags },
  } = useStaticQuery(graphql`
    query FiltersQuery {
      allSanityTag {
        edges {
          node {
            name
            _id
          }
        }
      }
    }
  `)

  return (
    <Filters>
      {tags.map(({ node: tag }, index) => {
        return <Filter tag={tag} key={`${Math.random() * index}-tag.id`} />
      })}
    </Filters>
  )
}
