import React from "react"
import styled from "styled-components"

const Pill = styled.p`
  width: fit-content;
  margin: 0;
  margin-right: 0.1rem;
  margin-bottom: 0.75rem;
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  padding: 4px 4.25px;
  border-radius: 0.75rem;
  color: var(--color-background);
  font-size: 1rem;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  @media (max-width: 800px) {
    margin-bottom: 0.25rem;
    padding: calc(4px * 0.75) calc(4.25px * 0.75);
    font-size: 0.7rem;
  }
`

const Title = styled.span`
  display: inline-block;
  padding-top: 0.5rem;
  padding-left: 11.75px;
  padding-right: 11.75px;
  background-color: ${({ highlight }) => highlight};
  color: ${({ highlight }) => {
    return highlight === "#ffffff" ? "#1d1d1b" : "#ffffff"
  }};
  border-radius: 0.45rem;
  margin: 0;
  @media (max-width: 800px) {
    display: block;
    text-align: center;
    line-height: 1.7;
    padding-top: calc(0.5rem * 0.75);
    padding-left: calc(11.75px * 0.75);
    padding-right: calc(11.75px * 0.75);
  }
`

const Value = styled.span`
  padding-top: 0.5rem;
  padding-left: 11.75px;
  padding-right: 11.75px;
  display: inline-block;
  margin: 0;
  text-align: left;
  @media (max-width: 800px) {
    display: block;
    line-height: 1.7;
    padding-top: calc(0.5rem * 0.75);
    padding-left: calc(11.75px * 0.75);
    padding-right: calc(11.75px * 0.75);
  }
`

export default props => {
  const { title, value, className, highlight = "#ffffff", showAs } = props
  if (!value && !title) {
    return null
  }
  return (
    <Pill className={className} {...props} as={showAs}>
      {title ? <Title highlight={highlight}>{title}</Title> : null}
      {value ? <Value hasTitle={!!value}>{value}</Value> : null}
    </Pill>
  )
}
