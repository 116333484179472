import React from "react"
import styled from "styled-components"

import Pill from "~components/pill"

const Description = styled(Pill)``

export default ({ description }) => {
  if (!description) {
    return null
  }
  return <Description value={description} />
}
