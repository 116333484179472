import React from "react"
import styled from "styled-components"

import Tag from "./tag"

const Tags = styled.div``

export default ({ allTags, projectTags, highlight }) => {
  // flatten project tags to array
  const projectTagsArray = projectTags.map(projectTag => projectTag.name)

  return (
    <Tags>
      {allTags.map(({ node: tag }, index) => {
        // check if this tag is tagged on this project
        const isProjectTag = projectTagsArray.includes(tag.name)
        return (
          <Tag
            tag={tag}
            key={`${Math.random() * index}-tag.id`}
            isProjectTag={isProjectTag}
            highlight={highlight}
          />
        )
      })}
    </Tags>
  )
}

// get all tags
