import React from "react"

// Import Global Components
// import Loader from "~components/loader"
import Header from "~components/header"
import Navigation from "~components/navigation"
import Footer from "~components/footer"

import "./globalStyles.css"

import ProjectIntersectionObserverContextProvider from "./components/navigationIntersectionObserverContextWrapper"
import FilterContextWrapper from "./components/filterContextWrapper"
import Background from "./components/background"

export default ({ children }) => {
  return (
    <ProjectIntersectionObserverContextProvider>
      <FilterContextWrapper>
        {/* <Loader /> */}
        <Navigation />
        <Header />
        <main>{children}</main>
        <Footer />
        <Background />
      </FilterContextWrapper>
    </ProjectIntersectionObserverContextProvider>
  )
}
