import React from "react"
import styled from "styled-components"

import Pill from "~components/pill"

const Credit = styled(Pill)``

export default ({ credit, highlight }) => {
  if (!credit) {
    return null
  }
  const credited = credit.credited.map((name, index) =>
    index === credit.credited.length - 1 ? `${name}` : `${name} + `
  )

  return <Credit title={credit.type} value={credited} highlight={highlight} />
}
