import React, { useState, useEffect, useContext } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import slug from "slug"

import FilterContext from "~context/filter"

import PillButton from "~components/pillButton"

const ProjectListItem = styled(PillButton)`
  overflow: hidden;
  position: ${({ filtered }) => (filtered ? "absolute" : "relative")};
  height: ${({ filtered }) => (filtered ? "0px" : "relative")};
  width: ${({ filtered }) => (filtered ? "0px" : "auto")};
  opacity: ${({ filtered }) => (filtered ? "0" : "1")};
  transition: opacity 0.3s, background-color 0.3s, color 0.3s;
  &:hover {
    background-color: var(--color-off-white);
    color: var(--color-primary);
  }
`

export default ({ project }) => {
  const [filtered, setFiltered] = useState(false)
  const [filters] = useContext(FilterContext)

  useEffect(() => {
    const projectTags = project.tags.map(tag => tag.name)
    if (filters.length === 0) {
      setFiltered(true)
    } else {
      setFiltered(true)
      // if filtered, set filtered true
      filters.forEach(filter => {
        if (projectTags.includes(filter)) {
          setFiltered(false)
        }
      })
    }
  }, [filters])

  return (
    <Link to={`/project/${slug(project.name, { lower: true })}`}>
      <ProjectListItem
        title={project.client}
        value={project.name}
        filtered={filtered}
      />
    </Link>
  )
}
