import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"

// Import Contexts
import FilterContext from "~context/filter"

// Import Global Components
import PillButton from "~components/pillButton"

const Filter = styled(PillButton)`
  &:hover {
    background-color: background-color: var(--color-off-white);
    color: var(--color-primary);
  }
`

export default ({ tag }) => {
  const [filters, setFilters] = useContext(FilterContext)
  const [active, setActive] = useState(filters.includes(tag.name))

  const handleClick = () => {
    if (active) {
      setFilters(
        filters.filter(filter => {
          return filter !== tag.name
        })
      )
    } else {
      setFilters(filters.concat([tag.name]))
    }
  }

  useEffect(() => {
    if (filters.includes(tag.name)) {
      setActive(true)
    } else {
      setActive(false)
    }
  }, [filters])

  if (active) {
    return <Filter title={tag.name} handleClick={handleClick} active={active} />
  } else {
    return <Filter value={tag.name} handleClick={handleClick} active={active} />
  }
}
